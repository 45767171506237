import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import Tile from "../../components/Tile.js";
import withNeon from "react-neon";
import clouds from "../../effects/clouds.js";
const Clouds = withNeon(Tile, clouds);
export const frontmatter = {
  title: 'Clouds (twgl.js)',
  author: 'Chris Neale',
  draft: false,
  date: '2018-11-29T23:46:37.121Z',
  tags: ['effect']
}
export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>

<MDXTag name="h3" components={components}>{`Effects / Clouds`}</MDXTag>
<MDXTag name="p" components={components}>{`The clouds effect puts IQ's raymarching clouds over a component.`}</MDXTag>
<MDXTag name="h3" components={components}>{`Options`}</MDXTag>
<MDXTag name="p" components={components}>{`Effect has no options.`}</MDXTag>
<MDXTag name="h3" components={components}>{`Example`}</MDXTag>
<Clouds mixmode="hard-light" />
<MDXTag name="h3" components={components}>{`Example code`}</MDXTag>
<div className="gatsby-highlight" data-language="javascript"><pre className="language-javascript"><code className="language-javascript"><span className="token keyword">import</span> Tile <span className="token keyword">from</span> <span className="token string">"../../components/Tile.js"</span><span className="token punctuation">;</span>{"\n"}<span className="token keyword">import</span> withNeon <span className="token keyword">from</span> <span className="token string">"react-neon"</span><span className="token punctuation">;</span>{"\n"}<span className="token keyword">import</span> clouds <span className="token keyword">from</span> <span className="token string">"../../effects/clouds.js"</span><span className="token punctuation">;</span>{"\n"}<span className="token keyword">const</span> Clouds <span className="token operator">=</span> <span className="token function">withNeon</span><span className="token punctuation">(</span>Tile<span className="token punctuation">,</span> clouds<span className="token punctuation">)</span><span className="token punctuation">;</span></code></pre></div>
</MDXTag>

export const _frontmatter = {};

  